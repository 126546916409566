"use strict";

// ---------- HOME PAGE ACCORDIONS -------------

function accordionsHandler() {
  var accordionToggles = document.querySelectorAll('input[data-accordion-toggle]');
  if (accordionToggles.length !== 0) {
    accordionToggles.forEach(function (toggle) {
      toggle.addEventListener('change', function (event) {
        if (event.target.type === 'checkbox') {
          var targetAccordion = event.target.closest('.brands-wrapper').querySelector('[data-accordion]');
          if (targetAccordion) {
            targetAccordion.classList.toggle('expanded');
          }
        }
      });
    });
  }
}
function agePopup() {
  //const access = localStorage.getItem('access');
  var popup = document.querySelector('.popup-container');
  popup.classList.add('visible');
  //document.querySelector('body').classList.add('scroll-lock');

  if (popup) {
    popup.addEventListener('click', function (event) {
      event.stopPropagation();
      if (event.target.value === 'false') {}
      if (event.target.value === 'true') {
        popup.classList.remove('visible');
        document.querySelector('body').classList.remove('scroll-lock');
      }
    });
  }
}
function agePopupWithLocalStorage() {
  var access = localStorage.getItem('access');
  var popup = document.querySelector('.popup-container');
  if (access) {
    if (access === 'granted') {
      popup.classList.add('hidden');
      document.querySelector('body').classList.remove('scroll-lock');
    } else {
      var enterButton = popup.querySelector('button[value="true"]');
      if (enterButton) {
        enterButton.disabled = true;
      }
      popup.classList.add('visible');
      document.querySelector('body').classList.add('scroll-lock');
    }
  } else {
    popup.classList.add('visible');
    document.querySelector('body').classList.add('scroll-lock');
  }
  if (popup) {
    popup.addEventListener('click', function (event) {
      event.stopPropagation();
      if (event.target.value === 'false') {
        localStorage.setItem('access', 'denied');
        var _enterButton = popup.querySelector('button[value="true"]');
        if (_enterButton) {
          _enterButton.disabled = true;
        }
      }
      if (event.target.value === 'true') {
        localStorage.setItem('access', 'granted');
        popup.classList.remove('visible');
        document.querySelector('body').classList.remove('scroll-lock');
      }
    });
  }
}
// ajax filter for brands

function ajaxFilter() {
  var catFilter = document.querySelector('.cat-filter');
  var catFilterBtns = document.querySelectorAll('.cat-filter .cat-btn');
  if (catFilter) {
    catFilter.addEventListener('click', toggleActiveBtn);
    catFilter.addEventListener('click', filterBrands);
  }
}
function toggleActiveBtn(event) {
  var catFilterBtns = document.querySelectorAll('.cat-filter .cat-btn');
  if (catFilterBtns.length !== 0) {
    catFilterBtns.forEach(function (btn) {
      if (event.target === btn) {
        btn.classList.add('active');
      } else {
        btn.classList.remove('active');
      }
    });
  }
}
function filterBrands(event) {
  var filter = event.target.dataset.filter;
  var filtered = document.querySelector('.filtered');
  $.ajax({
    type: 'POST',
    url: '/wp-admin/admin-ajax.php',
    dataType: 'html',
    data: {
      action: 'gsw_filtered_brand_logos',
      filter: filter
    },
    success: function success(res) {
      $(filtered).fadeOut(100, function () {
        $(this).html(JSON.parse(res).data).fadeIn(100);
      });
    }
  });
}
// fixing file upload button UI according to required design

function fileUploadBtnUI() {
  var fileGroups = document.querySelectorAll('.file-group');
  var fileButtons = document.querySelectorAll('.btn-select-file');
  if (fileGroups.length !== 0 && fileButtons.length !== 0) {
    fileButtons.forEach(function (btn) {
      btn.addEventListener('click', function () {
        btn.closest('.file-group').querySelector('input[type="file"]').click();
      });
    });
    fileGroups.forEach(function (fileGroup) {
      var inputFile = fileGroup.querySelector('input[type="file"]');
      if (inputFile) {
        inputFile.addEventListener('change', function (event) {
          var fileName = event.target.files[0].name;
          fileGroup.querySelector('.btn-select-file').textContent = 'File: ' + fileName;
        });
      }
    });
  }
}
// ---------------  FILTERS --------------------
function productsFilterHandler() {
  var filterSidebar = document.querySelector('.filter-sidebar');
  var filterOpenBtn = document.querySelector('[data-filter-toggle]');
  var filterCloseBtn = document.querySelector('[data-close-sidebar-btn]');
  var body = document.querySelector('body');
  var filtersVisibleCssClass = 'visible';
  var bodyScrollLockCssClass = 'scroll-lock';
  if (filterOpenBtn) {
    filterOpenBtn.addEventListener('click', function (event) {
      if (filterSidebar) {
        filterSidebar.classList.add(filtersVisibleCssClass);
        body.classList.add(bodyScrollLockCssClass);
        event.stopPropagation();
      }

      // DO NOT REMOVE/REPLACE CLICK HANDLER BELOW FROM THIS PART OF CODE
      // OTHERWISE FILTERS ARE NOT DISAPPEARING ON "APPLY" CLICK
      // AS "BEROCKET FILTERS" WORDPRESS PLUGIN REMOVES THIS EVENT LISTENER RIGHT WITH THE FIRST CLICK OF THE "APPLY" BUTTON
      var filterApplyBtn = document.querySelector('.filter-sidebar .bapf_button.bapf_update');
      filterApplyBtn.addEventListener('click', function (event) {
        filterSidebar.classList.remove(filtersVisibleCssClass);
        body.classList.remove(bodyScrollLockCssClass);
      });
    });
  }
  if (filterCloseBtn) {
    filterCloseBtn.addEventListener('click', function (event) {
      if (filterSidebar) {
        filterSidebar.classList.remove(filtersVisibleCssClass);
        body.classList.remove(bodyScrollLockCssClass);
        event.stopPropagation();
      }
    });
  }
}
function header() {
  headerSubMenuImage();
  headerMenuDropdowns();
  headerInnerSubMenuHeight();
  showMenuItemImageOnHover();
}

/*------------------ header desktop bottom menu dropdowns -------------------*/
function headerMenuDropdowns() {
  var topLevelMenuItemsWithChildren = document.querySelectorAll('.header-bottom-menu.desktop-menu>.menu-item-has-children');
  if (topLevelMenuItemsWithChildren.length !== 0) {
    topLevelMenuItemsWithChildren.forEach(function (menuItem) {
      // forcing first sub-menu of "by-cat/by-country/..." section
      // always visible on hover top level menu-items

      menuItem.addEventListener('mouseenter', function (event) {
        var firstInnerMenuItem = event.target.querySelector('.sub-menu>.menu-item-has-children:first-child');
        if (firstInnerMenuItem) {
          var firstInnerSubMenu = firstInnerMenuItem.querySelector('.sub-menu');
          if (firstInnerSubMenu) {
            setHeaderSubMenuContainerHeight(firstInnerSubMenu);
            firstInnerSubMenu.style.visibility = 'visible';
            firstInnerSubMenu.style.opacity = 1;
            firstInnerMenuItem.classList.add('current-menu-item');
            var subMenuImages = event.target.querySelectorAll('.submenu-images-wrapper [data-submenu-image]');
            var imageClasses = [];
            if (subMenuImages.length !== 0) {
              subMenuImages.forEach(function (subMenuImage) {
                imageClasses.push(subMenuImage.getAttribute('class'));
              });
            }
            var imageIndex = imageClasses.findIndex(function (imageClass) {
              return firstInnerMenuItem.classList.contains(imageClass);
            });
            if (imageIndex !== -1) {
              subMenuImages[imageIndex].style.visibility = 'visible';
            }
          }
        }
      });

      // removing visibility on mouseleave

      menuItem.addEventListener('mouseleave', function (event) {
        var firstInnerMenuItem = event.target.querySelector('.sub-menu>.menu-item-has-children:first-child');
        if (firstInnerMenuItem) {
          var firstInnerSubMenu = firstInnerMenuItem.querySelector('.sub-menu');
          if (firstInnerSubMenu) {
            firstInnerSubMenu.style.visibility = 'hidden';
            firstInnerSubMenu.style.opacity = 0;
            firstInnerMenuItem.classList.remove('current-menu-item');
            var subMenuImages = event.target.querySelectorAll('.submenu-images-wrapper [data-submenu-image]');
            var imageClasses = [];
            if (subMenuImages.length !== 0) {
              subMenuImages.forEach(function (subMenuImage) {
                imageClasses.push(subMenuImage.getAttribute('class'));
              });
            }
            var imageIndex = imageClasses.findIndex(function (imageClass) {
              return firstInnerMenuItem.classList.contains(imageClass);
            });
            if (imageIndex !== -1) {
              subMenuImages[imageIndex].style.visibility = 'hidden';
            }
          }
        }
      });
    });
  }
  var parentMenuItems = document.querySelectorAll('.header-bottom-menu.desktop-menu>.menu-item>.sub-menu>.menu-item');
  if (parentMenuItems.length !== 0) {
    // show/hide bottomLevelSubMenus

    parentMenuItems.forEach(function (parentMenuItem) {
      parentMenuItem.addEventListener('mouseenter', function (event) {
        var subMenu = event.target.querySelector('.sub-menu');
        if (subMenu) {
          setHeaderSubMenuContainerHeight(subMenu);
          subMenu.style.visibility = 'visible';
          subMenu.style.opacty = 1;
        }
        event.target.classList.add('current-menu-item');
      });
      parentMenuItem.addEventListener('mouseleave', function (event) {
        var subMenu = event.target.querySelector('.sub-menu');
        if (subMenu) {
          subMenu.style.visibility = 'hidden';
          subMenu.style.opacty = 0;
        }
        event.target.classList.remove('current-menu-item');
      });
    });
  }
  var bottomLevelSubMenus = document.querySelectorAll('.header-bottom-menu.desktop-menu>.menu-item>.sub-menu>.menu-item>.sub-menu');

  // set submenu offset to 0 relatively to submenus container

  if (bottomLevelSubMenus.length !== 0) {
    bottomLevelSubMenus.forEach(function (subMenu) {
      var menuPaddingTop = parseInt(getComputedStyle(subMenu.parentNode.parentNode).paddingTop);
      var offsetTop = subMenu.parentNode.offsetTop;
      subMenu.style.top = -offsetTop + menuPaddingTop + 'px';
    });
  }
}

/*------------------ header desktop bottom menu sub-menu image-------------------*/
function headerSubMenuImage() {
  var subMenusFirstLevel = document.querySelectorAll('.header-bottom-menu.desktop-menu>.menu-item>.sub-menu');
  var imageTempContainer = document.querySelector('.img-temp-container');
  if (imageTempContainer) {
    if (subMenusFirstLevel.length !== 1) {
      subMenusFirstLevel.forEach(function (subMenu) {
        var imageContainer = document.createElement('li');
        var image = document.createElement('img');
        image.setAttribute('class', 'submenu-image');
        imageContainer.setAttribute('class', 'submenu-images-wrapper');
        imageContainer.innerHTML = imageTempContainer.innerHTML;
        subMenu.appendChild(imageContainer);
        subMenu.classList.add('images-storage');
      });
    }
  }
}

/*------------------ header desktop bottom first-level sub-menu height-------------------*/
function setHeaderSubMenuContainerHeight(subMenu) {
  var subMenuHeight = parseInt(getComputedStyle(subMenu).height);
  var subMenuContainer = subMenu.closest('.header-bottom-menu.desktop-menu>.menu-item>.sub-menu');
  if (subMenuContainer) {
    var subMenuContainerHeight = parseInt(getComputedStyle(subMenuContainer).minHeight);
    var subMenuContainerPaddingTop = parseInt(getComputedStyle(subMenuContainer).paddingTop) || 0;
    var subMenuContainerPaddingBottom = parseInt(getComputedStyle(subMenuContainer).paddingBottom) || 0;
    if (subMenuContainerHeight) {
      if (subMenuContainerHeight < subMenuHeight) {
        subMenuContainer.style.height = subMenuHeight + subMenuContainerPaddingBottom + 'px';
      } else {
        subMenuContainer.style.height = subMenuContainerHeight + subMenuContainerPaddingBottom + 'px';
      }
    }
  }
}
function headerInnerSubMenuHeight() {
  var innerMenus = document.querySelectorAll('.header-bottom-menu.desktop-menu>.menu-item>.sub-menu>.menu-item>.sub-menu');
  var innerMenuMinHeight = 275;
  if (innerMenus.length !== 0) {
    innerMenus.forEach(function (innerMenu) {
      var innerMenuItems = innerMenu.querySelectorAll('.menu-item');
      if (innerMenuItems.length !== 0) {
        var innerMenuItemHeight = parseFloat(getComputedStyle(innerMenuItems[0]).height);
        var innerMenuHeight = (innerMenuItems.length + Math.ceil(innerMenuItems.length / 4)) * innerMenuItemHeight / 4;
        if (innerMenuHeight > innerMenuMinHeight) {
          innerMenu.style.height = innerMenuHeight + 'px';
        } else {
          innerMenu.style.height = innerMenuMinHeight + 'px';
        }
      }
    });
  }
}
function showMenuItemImageOnHover() {
  //const subMenuImages = document.querySelectorAll('.submenu-images-wrapper [data-submenu-image]');
  var subMenuItems = document.querySelectorAll('.header-bottom-menu.desktop-menu>.menu-item>.sub-menu .menu-item');
  if (subMenuItems.length !== 0) {
    subMenuItems.forEach(function (subMenuItem) {
      subMenuItem.addEventListener('mouseenter', function (event) {
        var currentImageWrapper = event.target.closest('.images-storage');
        var subMenuImages = currentImageWrapper.querySelectorAll('[data-submenu-image]');
        var imageClasses = [];
        if (subMenuImages.length !== 0) {
          subMenuImages.forEach(function (subMenuImage) {
            imageClasses.push(subMenuImage.getAttribute('class'));
          });
        }
        var imageIndex = imageClasses.findIndex(function (imageClass) {
          return subMenuItem.classList.contains(imageClass);
        });
        if (imageIndex !== -1) {
          subMenuImages[imageIndex].style.visibility = 'visible';
        }
      });
      subMenuItem.addEventListener('mouseleave', function (event) {
        var currentImageWrapper = event.target.closest('.images-storage');
        var subMenuImages = currentImageWrapper.querySelectorAll('[data-submenu-image]');
        var imageClasses = [];
        if (subMenuImages.length !== 0) {
          subMenuImages.forEach(function (subMenuImage) {
            imageClasses.push(subMenuImage.getAttribute('class'));
          });
        }
        var imageIndex = imageClasses.findIndex(function (imageClass) {
          return subMenuItem.classList.contains(imageClass);
        });
        if (imageIndex !== -1) {
          subMenuImages[imageIndex].style.visibility = 'hidden';
        }
      });
    });
  }
}
function mobileHeader() {
  var mobileMenuToggle = document.querySelector('.mobile-menu-toggle .checkbox');
  var body = document.querySelector('body');
  var BODY_SCROLL_LOCK_CLASS = 'scroll-lock';
  if (!mobileMenuToggle) {
    return;
  }

  // close mobile menu on click in not-mobile-container zone
  document.addEventListener("click", function (event) {
    if (event.target.closest('.mobile-menu-container')) {
      return;
    }
    mobileMenuToggle.checked = false;
    body.classList.remove(BODY_SCROLL_LOCK_CLASS);
  });
  mobileMenuToggle.addEventListener('click', toggleBodyScrollLock);

  // toggle body overflow when clicking on burger button
  function toggleBodyScrollLock(event) {
    event.stopPropagation();
    if (event.target.checked) {
      body.classList.add(BODY_SCROLL_LOCK_CLASS);
    } else {
      body.classList.remove(BODY_SCROLL_LOCK_CLASS);
    }
  }

  // ---- handling sticky header logo----

  var stickyHeaderLogo = document.querySelector('.sticky-header-logo');
  var headerLogo = document.querySelector('.header-logo');
  if (stickyHeaderLogo) {
    window.addEventListener('scroll', function () {
      if (window.scrollY >= 50 && window.matchMedia('(max-width: 1024px)').matches) {
        headerLogo.classList.remove('visible');
        stickyHeaderLogo.classList.add('visible');
      } else {
        headerLogo.classList.add('visible');
        stickyHeaderLogo.classList.remove('visible');
      }
    });
  }

  /*------------------ header mobile menu dropdowns -------------------*/

  var menuItemsWithChildren = document.querySelectorAll('.header-bottom-menu.mobile-menu .menu-item-has-children');
  if (menuItemsWithChildren.length !== 0) {
    menuItemsWithChildren.forEach(function (menuItem) {
      menuItem.querySelector('a').removeAttribute('href');
      menuItem.addEventListener('click', function (event) {
        event.stopPropagation();

        // we set stopPropagation(), we need to handle click on <a> which is inside <li>
        // so we take closest parent of <a>. it is '.menu-item-has-children'

        var linkParent = event.target.closest('.menu-item-has-children');
        if (linkParent) {
          var subMenu = linkParent.querySelector('.sub-menu');
          if (subMenu) {
            subMenu.classList.toggle('visible');
          }
        }
      });
    });
  }
}
// ---------------  MOBILE MENU  --------------------
function mobileMenuHandler() {
  var mobileMenuToggle = document.querySelector('.mobile-menu-toggle .checkbox');
  var body = document.querySelector('body');
  var bodyScrollLockCssClass = 'scroll-lock';
  if (!mobileMenuToggle) {
    return;
  }

  // close mobile menu on click in not-mobile-container zone
  /*document.addEventListener('click', (event) => {
    if(event.target.closest('.mobile-menu-container')){
      return;
    }
    mobileMenuToggle.checked = false;
    body.classList.remove(bodyScrollLockCssClass);
  });*/

  // toggle body overflow when clicking on burger button
  mobileMenuToggle.addEventListener('click', function (event) {
    event.stopPropagation();
    if (event.target.checked) {
      body.classList.add(bodyScrollLockCssClass);
    } else {
      body.classList.remove(bodyScrollLockCssClass);
    }
  });
}

// nodeList foreach

(function () {
  if (window.NodeList && !NodeList.prototype.forEach) {
    NodeList.prototype.forEach = Array.prototype.forEach;
  }
})();

// matches

(function () {
  if (!Element.prototype.matches) {
    Element.prototype.matches = Element.prototype.matchesSelector || Element.prototype.webkitMatchesSelector || Element.prototype.mozMatchesSelector || Element.prototype.msMatchesSelector;
  }
})();

// closest
(function () {
  if (!Element.prototype.closest) {
    Element.prototype.closest = function (css) {
      var node = this;
      while (node) {
        if (node.matches(css)) return node;else node = node.parentElement;
      }
      return null;
    };
  }
})();
function preloader() {
  var preloader = document.getElementById('preloader');
  if (preloader) {
    preloader.classList.add('hide-preloader');
    setInterval(function () {
      preloader.classList.add('preloader-hidden');
    }, 500);
  }
}
// ---------------  POLYFILLS --------------------

// nodeList foreach

(function () {
  if (window.NodeList && !NodeList.prototype.forEach) {
    NodeList.prototype.forEach = Array.prototype.forEach;
  }
})();

// matches

(function () {
  if (!Element.prototype.matches) {
    Element.prototype.matches = Element.prototype.matchesSelector || Element.prototype.webkitMatchesSelector || Element.prototype.mozMatchesSelector || Element.prototype.msMatchesSelector;
  }
})();

// closest
(function () {
  if (!Element.prototype.closest) {
    Element.prototype.closest = function (css) {
      var node = this;
      while (node) {
        if (node.matches(css)) return node;else node = node.parentElement;
      }
      return null;
    };
  }
})();
window.addEventListener('load', function () {
  preloader();
  agePopup();
  mobileHeader();
  header();
  fileUploadBtnUI();
  slidersHandler();
  accordionsHandler();
  productsFilterHandler();
  videoStartButtonHandler();
  ajaxFilter();
});

// ---------------  SLIDERS --------------------
function slidersHandler() {
  $('.hero-slider').slick({
    dots: true,
    infinite: true,
    speed: 500,
    fade: true,
    cssEase: 'linear',
    arrows: true,
    autoplay: true
  });
  $('.new-arrivals .woocommerce .cards-loop').slick({
    dots: false,
    infinite: true,
    speed: 750,
    arrows: true,
    autoplay: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [{
      breakpoint: 1024,
      settings: {
        slidesToShow: 2
      }
    }, {
      breakpoint: 576,
      settings: {
        slidesToShow: 2,
        arrows: false
      }
    }]
  });
  $('.featured-products .woocommerce .cards-loop').slick({
    dots: false,
    infinite: true,
    speed: 750,
    arrows: true,
    autoplay: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [{
      breakpoint: 1024,
      settings: {
        slidesToShow: 2
      }
    }, {
      breakpoint: 576,
      settings: {
        slidesToShow: 2,
        arrows: false
      }
    }]
  });

  // ---- single product gallery ----

  $('.single-product-gallery .gallery-images').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    asNavFor: '.single-product-gallery .gallery-nav',
    centerMode: true
  });
  $('.single-product-gallery .gallery-nav').slick({
    slidesToShow: 3,
    slidesToScroll: 1,
    asNavFor: '.single-product-gallery .gallery-images',
    focusOnSelect: true,
    arrows: false,
    vertical: true,
    verticalSwiping: true,
    infinite: false,
    responsive: [{
      breakpoint: 1024,
      settings: {
        vertical: false,
        verticalSwiping: false
      }
    }]
  });
  $('.single-product-gallery .gallery-nav .gallery-nav-thumbnail').on('mouseover', function () {
    $('.single-product-gallery .gallery-images').slick('goTo', $(this).index());
  });
}
function stickyHeaderHandler() {
  var header = document.querySelector('.header');
  var headerY = header.getBoundingClientRect().y;
  var headerHeight = header.getBoundingClientRect().height;
  var contentWrapper = document.querySelector('.content-wrapper');
  if (window.scrollY >= headerY) {
    header.classList.add('sticky');
    contentWrapper.style.paddingTop = headerHeight + 'px';
  }
  window.addEventListener('scroll', function () {
    if (window.scrollY >= headerY) {
      header.classList.add('sticky');
      contentWrapper.style.paddingTop = headerHeight + 'px';
    } else {
      header.classList.remove('sticky');
      contentWrapper.style.paddingTop = 0;
    }
  });
  // ---- handling sticky header logo <1024px ----

  /*const stickyHeaderLogo = document.querySelector('.sticky-header-logo');
  const headerLogo = document.querySelector('.header-logo');
    if(stickyHeaderLogo){
    window.addEventListener('scroll', () => {
      if(window.scrollY >= 50  && window.matchMedia('(max-width: 1024px)').matches){
        headerLogo.classList.remove('visible');
        stickyHeaderLogo.classList.add('visible');
      }else{
        headerLogo.classList.add('visible');
        stickyHeaderLogo.classList.remove('visible');
      }
    });
  }*/
}

function videoStartButtonHandler() {
  var videoStartButton = document.querySelector('[data-video-start-btn]');
  var video = document.querySelector('.team-video');
  var videoOverlay = document.querySelector('.video-overlay');
  if (videoStartButton) {
    videoStartButton.addEventListener('click', function () {
      if (video) {
        video.setAttribute('controls', 'controls');
        video.play();
      }
      if (videoOverlay) {
        videoOverlay.classList.add('hidden');
      }
    });
  }
}